export const SET_COUPON = "SET_COUPON";
export const SET_CHECKOUT_ITEMS = "SET_CHECKOUT_ITEMS";
export const SET_SUBTOTAL = "SET_SUBTOTAL";
export const SET_METHOD = "SET_METHOD";
export const SET_BILLING_INFO = 'SET_BILLING_INFO';
export const SET_PERSONAL_INFO = 'SET_PERSONAL_INFO';
export const SET_TRANSACTION = 'SET_TRANSACTION';
export const SET_ERROR = 'SET_ERROR';
export const SET_PROOF = 'SET_PROOF';
export const CLEAR_CHECKOUT = 'CLEAR_CHECKOUT';
export const CLEAR_CHECKOUT_WITHOUT_ITEMS = 'CLEAR_CHECKOUT_WITHOUT_ITEMS';
export const SET_PROCEED_PAYMENT = 'SET_PROCEED_PAYMENT'
