export const areaCode = [
    {
       "countryCode":"US", 
       "countryName": "美國", 
       "phoneCode": "+1"
    },
    {
       "countryCode":"CA", 
       "countryName": "加拿大", 
       "phoneCode": "+1"
    },
    {
       "countryCode":"UK", 
       "countryName": "英國", 
       "phoneCode": "+44"
    },
    {
       "countryCode":"AF", 
       "countryName": "阿富汗", 
       "phoneCode": "+93"
    },
    {
       "countryCode":"AR", 
       "countryName": "阿根廷", 
       "phoneCode": "+54"
    },
    {
       "countryCode":"AT", 
       "countryName": "奧地利", 
       "phoneCode": "+43"
    },
    {
       "countryCode":"AU", 
       "countryName": "澳洲", 
       "phoneCode": "+61"
    },
    {
       "countryCode":"BH", 
       "countryName": "巴林", 
       "phoneCode": "+973"
    },
    {
       "countryCode":"BD", 
       "countryName": "孟加拉", 
       "phoneCode": "+880"
    },
    {
       "countryCode":"BE", 
       "countryName": "比利時", 
       "phoneCode": "+32"
    },
    {
       "countryCode":"BT", 
       "countryName": "不丹", 
       "phoneCode": "+975"
    },
    {
       "countryCode":"BO", 
       "countryName": "玻利維亞", 
       "phoneCode": "+591"
    },
    {
       "countryCode":"BR", 
       "countryName": "巴西", 
       "phoneCode": "+55"
    },
    {
       "countryCode":"KH", 
       "countryName": "柬埔寨", 
       "phoneCode": "+855"
    },
    {
       "countryCode":"CM", 
       "countryName": "喀麥隆", 
       "phoneCode": "+237"
    },
    {
       "countryCode":"CN", 
       "countryName": "中國", 
       "phoneCode": "+86"
    },
    {
       "countryCode":"AI", 
       "countryName": "安圭拉", 
       "phoneCode": "+1264"
    },
    {
       "countryCode":"AG", 
       "countryName": "安地瓜", 
       "phoneCode": "+1268"
    },
    {
       "countryCode":"AW", 
       "countryName": "阿魯巴", 
       "phoneCode": "+297"
    },
    {
       "countryCode":"BM", 
       "countryName": "百慕達", 
       "phoneCode": "+1441"
    },
    {
       "countryCode":"DO", 
       "countryName": "多明尼加", 
       "phoneCode": "+1767"
    },
    {
       "countryCode":"GD", 
       "countryName": "格瑞那達", 
       "phoneCode": "+1473"
    },
    {
       "countryCode":"LC", 
       "countryName": "聖盧西亞", 
       "phoneCode": "+1758"
    },
    {
       "countryCode":"CO", 
       "countryName": "哥倫比亞", 
       "phoneCode": "+57"
    },
    {
       "countryCode":"CG", 
       "countryName": "剛果共和國", 
       "phoneCode": "+243"
    },
    {
       "countryCode":"CH", 
       "countryName": "瑞士", 
       "phoneCode": "+41"
    },
    {
       "countryCode":"DE", 
       "countryName": "德國", 
       "phoneCode": "+49"
    },
    {
       "countryCode":"DK", 
       "countryName": "丹麥", 
       "phoneCode": "+45"
    },
    {
       "countryCode":"EG", 
       "countryName": "埃及", 
       "phoneCode": "+20"
    },
    {
       "countryCode":"ES", 
       "countryName": "西班牙", 
       "phoneCode": "+34"
    },
    {
       "countryCode":"SV", 
       "countryName": "薩爾瓦多", 
       "phoneCode": "+503"
    },
    {
       "countryCode":"FI", 
       "countryName": "芬蘭", 
       "phoneCode": "+358"
    },
    {
       "countryCode":"FJ", 
       "countryName": "斐濟", 
       "phoneCode": "+679"
    },
    {
       "countryCode":"FR", 
       "countryName": "法國", 
       "phoneCode": "+33"
    },
    {
       "countryCode":"GE", 
       "countryName": "喬治亞", 
       "phoneCode": "+995"
    },
    {
       "countryCode":"GH", 
       "countryName": "迦納", 
       "phoneCode": "+233"
    },
    {
       "countryCode":"GR", 
       "countryName": "希臘", 
       "phoneCode": "+30"
    },
    {
       "countryCode":"GT", 
       "countryName": "瓜地馬拉", 
       "phoneCode": "+502"
    },
    {
       "countryCode":"GY", 
       "countryName": "蓋亞那", 
       "phoneCode": "+967"
    },
    {
       "countryCode":"HT", 
       "countryName": "海地", 
       "phoneCode": "+509"
    },
    {
       "countryCode":"HN", 
       "countryName": "宏都拉斯", 
       "phoneCode": "+504"
    },
    {
       "countryCode":"HK", 
       "countryName": "香港", 
       "phoneCode": "+852"
    },
    {
       "countryCode":"IN", 
       "countryName": "印度", 
       "phoneCode": "+91"
    },
    {
       "countryCode":"IS", 
       "countryName": "冰島", 
       "phoneCode": "+354"
    },
    {
       "countryCode":"ID", 
       "countryName": "印尼", 
       "phoneCode": "+62"
    },
    {
       "countryCode":"IQ", 
       "countryName": "伊拉克", 
       "phoneCode": "+964"
    },
    {
       "countryCode":"IE", 
       "countryName": "愛爾蘭", 
       "phoneCode": "+353"
    },
    {
       "countryCode":"IT", 
       "countryName": "義大利", 
       "phoneCode": "+39"
    },
    {
       "countryCode":"JM", 
       "countryName": "牙買加", 
       "phoneCode": "+1876"
    },
    {
       "countryCode":"JO", 
       "countryName": "約旦", 
       "phoneCode": "+962"
    },
    {
       "countryCode":"KZ", 
       "countryName": "哈薩克", 
       "phoneCode": "+7"
    },
    {
       "countryCode":"KE", 
       "countryName": "肯亞", 
       "phoneCode": "+254"
    },
    {
       "countryCode":"JP", 
       "countryName": "日本", 
       "phoneCode": "+81"
    },
    {
       "countryCode":"KP", 
       "countryName": "韓國", 
       "phoneCode": "+82"
    },
    {
       "countryCode":"KW", 
       "countryName": "科威特", 
       "phoneCode": "+965"
    },
    {
       "countryCode":"LU", 
       "countryName": "盧森堡", 
       "phoneCode": "+352"
    },
    {
       "countryCode":"MO", 
       "countryName": "澳門", 
       "phoneCode": "+853"
    },
    {
       "countryCode":"MK", 
       "countryName": "馬其頓", 
       "phoneCode": "+389"
    },
    {
       "countryCode":"MG", 
       "countryName": "馬達加斯加", 
       "phoneCode": "+261"
    },
    {
       "countryCode":"ML", 
       "countryName": "馬來西亞", 
       "phoneCode": "+60"
    },
    {
       "countryCode":"MV", 
       "countryName": "馬爾地夫", 
       "phoneCode": "+960"
    },
    {
       "countryCode":"MX", 
       "countryName": "墨西哥", 
       "phoneCode": "+52"
    },
    {
       "countryCode":"MA", 
       "countryName": "摩洛哥", 
       "phoneCode": "+212"
    },
    {
       "countryCode":"NO", 
       "countryName": "挪威", 
       "phoneCode": "+47"
    },
    {
       "countryCode":"NR", 
       "countryName": "諾魯", 
       "phoneCode": "+674"
    },
    {
       "countryCode":"NZ", 
       "countryName": "紐西蘭", 
       "phoneCode": "+64"
    },
    {
       "countryCode":"NI", 
       "countryName": "尼加拉瓜", 
       "phoneCode": "+505"
    },
    {
       "countryCode":"NG", 
       "countryName": "尼日利亞", 
       "phoneCode": "+234"
    },
    {
       "countryCode":"PK", 
       "countryName": "巴基斯坦", 
       "phoneCode": "+92"
    },
    {
       "countryCode":"PA", 
       "countryName": "巴拿馬", 
       "phoneCode": "+507"
    },
    {
       "countryCode":"PG", 
       "countryName": "巴布亞紐幾內亞", 
       "phoneCode": "+675"
    },
    {
       "countryCode":"PT", 
       "countryName": "葡萄牙", 
       "phoneCode": "+351"
    },
    {
       "countryCode":"PY", 
       "countryName": "巴拉圭", 
       "phoneCode": "+595"
    },
    {
       "countryCode":"RO", 
       "countryName": "羅馬尼亞", 
       "phoneCode": "+40"
    },
    {
       "countryCode":"RU", 
       "countryName": "俄羅斯", 
       "phoneCode": "+7"
    },
    {
       "countryCode":"RW", 
       "countryName": "盧旺達", 
       "phoneCode": "+250"
    },
    {
       "countryCode":"SA", 
       "countryName": "沙烏地阿拉伯", 
       "phoneCode": "+966"
    },
    {
       "countryCode":"SY", 
       "countryName": "敘利亞", 
       "phoneCode": "+381"
    },
    {
       "countryCode":"SC", 
       "countryName": "塞席爾", 
       "phoneCode": "+248"
    },
    {
       "countryCode":"LK", 
       "countryName": "斯里蘭卡", 
       "phoneCode": "+94"
    },
    {
       "countryCode":"SG", 
       "countryName": "新加坡", 
       "phoneCode": "+65"
    },
    {
       "countryCode":"SD", 
       "countryName": "蘇丹", 
       "phoneCode": "+249"
    },
    {
       "countryCode":"SE", 
       "countryName": "瑞典", 
       "phoneCode": "+46"
    },
    {
       "countryCode":"TL", 
       "countryName": "泰國", 
       "phoneCode": "+66"
    },
    {
       "countryCode":"TW", 
       "countryName": "台灣", 
       "phoneCode": "+886"
    },
    {
       "countryCode":"TO", 
       "countryName": "湯加群島", 
       "phoneCode": "+676"
    },
    {
       "countryCode":"TR", 
       "countryName": "土耳其", 
       "phoneCode": "+90"
    },
    {
       "countryCode":"UG", 
       "countryName": "烏干達", 
       "phoneCode": "+256"
    },
    {
       "countryCode":"UA", 
       "countryName": "烏克蘭", 
       "phoneCode": "+380"
    },
    {
       "countryCode":"AE", 
       "countryName": "阿拉伯聯合大公國", 
       "phoneCode": "+971"
    },
    {
       "countryCode":"UY", 
       "countryName": "烏拉圭", 
       "phoneCode": "+598"
    },
    {
       "countryCode":"UZ", 
       "countryName": "烏茲別克", 
       "phoneCode": "+998"
    },
    {
       "countryCode":"VE", 
       "countryName": "委內瑞拉", 
       "phoneCode": "+58"
    },
    {
       "countryCode":"YE", 
       "countryName": "葉門", 
       "phoneCode": "+967"
     }
 ]